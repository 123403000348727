var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import config from 'config';
import { signOut } from '../context/Auth';
import { AUTHENTICATE_USER_TOKENS } from '../utils/storageConstants';
var isRefreshing = false;
var failedRequestsQueue = [];
var api = axios.create({
    baseURL: config.apiUrl,
    headers: {
        Authorization: "Bearer ".concat(getAccessToken()),
    },
});
if (config.env === 'development') {
    api.interceptors.request.use(function (config) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 2000); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/, config];
            }
        });
    }); });
}
// Función para obtener el token de acceso del almacenamiento local
function getAccessToken() {
    var tokens = localStorage.getItem(AUTHENTICATE_USER_TOKENS);
    var parseTokens = JSON.parse(tokens || '{}');
    return parseTokens.token || '';
}
// Interceptores de respuesta para manejar el token expirado
api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    var _a, _b, _c, _d;
    var originalRequest = error.config;
    if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 &&
        ((_b = error.response.data) === null || _b === void 0 ? void 0 : _b.error_code) === 'token.expired') {
        if (!isRefreshing) {
            isRefreshing = true;
            var refreshToken = getRefreshToken();
            if (!refreshToken) {
                return Promise.reject(error);
            }
            return refreshAccessToken(refreshToken)
                .then(function (newToken) {
                retryFailedRequests(newToken);
                originalRequest.headers.Authorization = "Bearer ".concat(newToken);
                return axios(originalRequest);
            })
                .catch(function (refreshError) {
                return Promise.reject(refreshError);
            })
                .finally(function () {
                isRefreshing = false;
            });
        }
        else {
            // Si ya hay una solicitud de actualización en curso, añadir la solicitud fallida a la cola
            return new Promise(function (resolve, reject) {
                failedRequestsQueue.push({
                    onSuccess: function (token) {
                        originalRequest.headers.Authorization = "Bearer ".concat(token);
                        resolve(axios(originalRequest));
                    },
                    onFailure: function (err) {
                        reject(err);
                    },
                });
            });
        }
    }
    if (((_c = error.response) === null || _c === void 0 ? void 0 : _c.status) === 401 &&
        ((_d = error.response.data) === null || _d === void 0 ? void 0 : _d.error_code) !== 'token.expired') {
        signOut();
    }
    return Promise.reject(error);
});
// Función para obtener el token de actualización del almacenamiento local
function getRefreshToken() {
    var tokens = localStorage.getItem(AUTHENTICATE_USER_TOKENS);
    var parseTokens = JSON.parse(tokens || '{}');
    return parseTokens.token_refresh || '';
}
// Función para actualizar el token de acceso
function refreshAccessToken(refreshToken) {
    return api
        .post("/v1/sessions/refresh-token/".concat(refreshToken))
        .then(function (response) {
        var _a = response.data, newRefreshToken = _a.token_refresh, token = _a.token;
        localStorage.setItem(AUTHENTICATE_USER_TOKENS, JSON.stringify({ token_refresh: newRefreshToken, token: token }));
        api.defaults.headers.common.Authorization = "Bearer ".concat(token);
        return token;
    });
}
// Función para reintentar las solicitudes fallidas
function retryFailedRequests(token) {
    failedRequestsQueue.forEach(function (request) { return request.onSuccess(token); });
    failedRequestsQueue = [];
}
export default api;
